<template>
  <section class="client-pending-booking__wrapper">
    <template v-if="$mediaQueries.isDesktop">
      <header class="client-pending-booking__header">
        <Heading
          :level="'h3'"
          :tag="'h2'"
          isCapitalized>
          {{ pendingBookingDate }}
        </Heading>
      </header>
      <img
        :src="croppedProfilePictureURL"
        alt="Artist profil picture"
        class="client-pending-booking__avatar"
      />
      <Heading
        :tag="'h3'"
        :level="'h3'"
        isCapitalized
        class="client-pending-booking__name">
        {{ artist.Firstname }}
      </Heading>
      <Paragraph
        :tag="'h3'"
        :level="'h3'"
        isCapitalized
        class="client-pending-booking__nickname">
        @{{ artist.ArtisteNicknameGenerated }}
      </Paragraph>
      <Heading
        :tag="'h5'"
        :level="'h5'"
        isCapitalized
        class="client-pending-booking__activity">
        {{ $t(getActivityTranslation(artist.Activity)) }}
      </Heading>
    </template>
    <template v-else>
      <img
        :src="croppedProfilePictureURL"
        alt="Artist profil picture"
        class="client-pending-booking__avatar"
      />
      <Heading
        :tag="'h3'"
        :level="'h3'"
        isCapitalized
        class="client-pending-booking__name">
        {{ artist.Firstname }}
      </Heading>
      <Paragraph
        isBold
        class="client-pending-booking__date">
        {{ pendingBookingDate }}
      </Paragraph>
    </template>
  </section>
</template>

<script>

import { mapState }                     from 'vuex';

import Heading                          from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph                        from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import { getCroppedPictureURL }         from '../../../utils/URLUtils.js';
import { getActivityTranslation }       from '../../../utils/i18nUtils.js';
import { getDateAsLocalizedString }     from '../../../utils/dateUtils.js';


export default {
  name: 'o-ClientPendingBooking',
  components: {
    Heading,
    Paragraph,
  },
  props: {
    artist: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('SearchArtist', ['selectedAvailability']),
    croppedProfilePictureURL() {
      const { URLProfilePictures, ProfilePicturesCropsOptions } = this.artist;

      return this.getCroppedPictureURL(URLProfilePictures, ProfilePicturesCropsOptions, {}, { c: 'fill', w: 100, h: 100, });
    },
    pendingBookingDate() {
      return this.selectedAvailability.timestamp
        ? getDateAsLocalizedString({ date: new Date(this.selectedAvailability.timestamp) })
        : '';
    },
  },
  methods: {
    getCroppedPictureURL,
    getActivityTranslation,
  },
}

</script>

<style lang="scss">

.client-pending-booking {
  &__wrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "avatar name"
      "avatar date"
    ;
    grid-column-gap: var(--space-base);
    padding: var(--space-md);
    border-radius: var(--rounded-xl);
    background-color: var(--color-grey-background);

    @media screen and ($desktop) {
      position: fixed;
      top: 50%;
      left: 25%;
      grid-template-columns: auto 1fr 1fr;
      grid-template-rows: auto 1fr 1fr;
      grid-template-areas:
        "header header header"
        "avatar name activity"
        "avatar nickname activity"
      ;
      min-width: 400px;
      min-height: 200px;
      align-items: center;
      padding: 0 var(--space-lg);
      background-color: var(--color-white);
      transform: translate(-50%, -50%);
    }
  }

  &__header {
    grid-area: header;
    padding: var(--space-lg);
    margin: 0 calc(-1 * var(--space-lg));
    background-color: var(--color-grey-light);
    border-top-left-radius: var(--rounded-xl);
    border-top-right-radius: var(--rounded-xl);
  }

  &__avatar {
    grid-area: avatar;
    height: 50px;
    width: 50px;
    border: 1px solid var(--color-grey-light);
    border-radius: var(--rounded-xs);
  }

  &__name {
    align-self: end;
    grid-area: name;
  }

  &__nickname {
    align-self: start;
    grid-area: nickname;
  }

  &__activity {
    grid-area: activity;
  }

  &__date {
    grid-area: date;
  }
}

</style>
