import { COMMON_API_RESPONSES }     from '../../../../constants/common-api-responses';


export const CLIENT_SIGN_IN_RESPONSE = {
    // Success
    client: 'Client',
    // Managed Failures
    userNotFound: 'UserNotFound',
    wrongPassword: 'WrongPassword',
    wrongInput: 'WrongInput',
    emailNotValidated: 'EmailNotValidate',
    ...COMMON_API_RESPONSES
};
