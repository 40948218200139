import gql      from 'graphql-tag';


export const CLIENT_SIGN_IN = gql`
mutation loginClient ($input: LoginInput!) {
  loginClient(input: $input) {
    __typename
    ... on Client {
      __typename

      ID
      Token
      Email
      Firstname
      Lastname
      CreatedAt
    }

    ... on EmailNotValidate {
      __typename
      message
      status
    }

    ... on WrongPassword {
      __typename
      message
      status
    }

    ... on UserNotFound {
      __typename
      message
      status
    }

    ... on WrongInput {
      __typename
      errors {
        message
        status
      }
    }
  }
}
`;
